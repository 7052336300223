var render = function render(){var _vm=this,_c=_vm._self._c;return _c('p-form',{on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('div',{staticClass:"c-row ui-mb-4"},[_c('div',{staticClass:"c-col"},[_c('div',{staticClass:"c-text-h3"},[_vm._v(" Cadastre sua nova senha ")]),_c('div',{staticClass:"c-text-b2 c-text-gray"},[_vm._v(" *Preenchimento obrigatório ")])])]),_c('div',{staticClass:"c-row ui-mb-4"},[_c('validation-provider',{staticClass:"c-col-full sm:c-col",attrs:{"rules":"required|min:5","vid":"password","custom-messages":{
        required: 'Senha é obrigatória',
        min: 'A senha é muito curta'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input-password',{attrs:{"label":"Nova Senha*","error-message":errors[0],"icon-info":"info"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('validation-provider',{staticClass:"c-col-full sm:c-col",attrs:{"rules":"required|confirmed:password","custom-messages":{
        required: 'Confirmação de senha é obrigatória',
        confirmed: 'Senhas não são iguais'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input-password',{attrs:{"label":"Confirmar nova senha*","error-message":errors[0]},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})]}}],null,true)}),_c('separator')],1),_c('div',{staticClass:"row ui-mb-4"},[(_vm.errors.length > 0)?_c('div',{staticClass:"q-mt-md text-negative"},[_c('q-list',_vm._l((_vm.errors),function(error,index){return _c('div',{key:index},[_vm._v(" * "+_vm._s(error)+" ")])}),0)],1):_vm._e()]),_c('div',{staticClass:"c-row"},[_c('div',{staticClass:"c-col-full sm:c-col-1/2"},[_c('c-button',{staticClass:"ui-w-full",attrs:{"type":"submit","disabled":invalid,"loading":_vm.isFormSending}},[_vm._v(" Cadastrar ")])],1)])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }